import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ProtectedRoute from "hoc/ProtectedRoute";
import Index from "components/Index/Index";
import CreatePost from "./components/CreatePost";
import ShowPost from "./components/Login/ShowPost";
import Login from "./components/Login/Login";
import RoleSelection from "./components/Register/RoleSelection";
import BuyerRegistration from "./components/Register/BuyerRegistration";
import SellerRegistration from "./components/Register/SellerRegistration";
import UserFieldAdder from "./components/Register/Info/ShowComponent";

import CreateComponent from "components/Register/Info/CreateComponent";
import UpdateUserFieldComponent from "components/Register/FieldUsers/UpdateUserFieldComponent";

import CreateUserFieldComponent from "components/Register/FieldUsers/CreateUserFieldComponent";
import GetAllFieldComponent from "components/Register/FieldUsers/GetAllFieldComponent";

import UsersComponent from "components/User/UsersComponent";

import ImageUploader from "components/Images/ImageUploader";

import SmsSender from "./components/Sms/SmsSender";

import VKAuth from "./components/Register/VKAuth";
import VKAuthTest from "./components/Register/VKAuthTest";

import Test from "./components/Test";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "./css/app.css";
import "css/styles.css";
import "css/media-queries.css";
import "css/AlertMessage.css";
import Reg from "components/Register/RegComponent";
import CreateCategoryForm from "components/categories/CreateCategoryForm";
import CategoryList from "components/categories/CategoryList";
import UpdateCategoryForm from "components/categories/UpdateCategoryForm";
import { AlertProvider } from "context/AlertContext";
import AlertMessage from "components/AlertMessage";
import CreateProductForm from "components/products/CreateProductForm";
import UpdateProductForm from "components/products/UpdateProductForm";
import CreateAttribute from "components/products/attributes/CreateAtribute";
import ProductsComponent from "components/products/ProductsComponent";
import UpdateAttributeComponent from "components/products/attributes/UpdateAttributeComponent";
import AttributesComponent from "components/products/attributes/AttributesComponent";
import BreathCircle from "components/BreathCircle/BreathCircle";
import { ModalProvider } from "components/ConfirmModal";
import { ExerciseProstate } from "components/ExerciseProstate";
import CreateStore from "./components/Store/CreateStore";
import UpdateStore from "./components/Store/UpdateStore";
import StoresList from "./components/Store/StoresList";
import VkIdLogin from "./components/VkIdLogin/VkIdLogin";
import RedirectHandler from "./components/VkIdLogin/RedirectHandler";
import CreateFillingForm from "./components/Fillings/CreateFillingForm";
import UpdateFillingForm from "./components/Fillings/UpdateFillingForm";
import FillingsList from "./components/Fillings/FillingsList";
import Database from "./components/Database/Database";
import OAuthRedirect from "components/YandexLogin/OAuthRedirect";
import RedirectHandlerOk from "./components/OkLogin/RedirectHandlerOk";
import RedirectHandlerMail from "./components/MailLogin/redirectHandlerMail";
import RedirectHandlerTelegram from "./components/TelegramAuth/RedirectHandlerTelegram";
import TelegramLogin from "./components/TelegramAuth/TelegramLogin";
import CreateArticleForm from "./components/Articles/CreateArticleForm";
import UpdateArticleForm from "./components/Articles/UpdateArticleForm";
import ArticlesList from "./components/Articles/ArticlesList";
import TokenHandlerYandexDirect from "./components/YandexDirect/TokenHandlerYandexDirect";
import TokenHandlerYandexWebmaster from "./components/YandexWebmaster/TokenHandlerYandexWebmaster";
import GenerateSitemap from "./components/sitemap/GenerateSitemap";
import VisitsByDate from "components/Visits/VisitsByDate";
import VisitsDetail from "components/Visits/VisitsDetail";
import AddCommentPage from "pages/AddCommentPage";
import AdminAddCommentPage from "pages/AdminAddCommentPage";
import UpdateCommentPage from "pages/UpdateCommentPage";
import AllCommentsPage from "pages/AllCommentsPage";

// import './js/app.js';
// import './js/script.js';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ModalProvider>
        <AlertProvider>
          <Router>
            <Routes>
              {/* Start маршруты для авторизованных пользователей */}
              <Route element={<ProtectedRoute />}>
                <Route
                  path="/create-field-user"
                  element={<CreateUserFieldComponent />}
                />
                <Route
                  path="/show-field-user"
                  element={<GetAllFieldComponent />}
                />
                <Route
                  path="/update-field-user/:id"
                  element={<UpdateUserFieldComponent />}
                />
                <Route path="/index" element={<Index />} />
                <Route path="/create-info" element={<CreateComponent />} />
                <Route path="/show-info/" element={<UserFieldAdder />} />
                <Route
                  path="/update-info/:id"
                  element={<UpdateAttributeComponent />}
                />
                <Route path="/test" element={<Test />} />
                <Route path="/sms" element={<SmsSender />} />
                <Route path="/create-post" element={<CreatePost />} />
                <Route path="/show-post" element={<ShowPost />} />
                <Route path="/register/buyer" element={<BuyerRegistration />} />
                <Route
                  path="/register/seller"
                  element={<SellerRegistration />}
                />
                <Route path="/test-img" element={<ImageUploader />} />
                <Route path="/users" element={<UsersComponent />} />
                {/* Start категории */}
                <Route
                  path="/create-category"
                  element={<CreateCategoryForm />}
                />
                <Route path="/show-category" element={<CategoryList />} />
                <Route
                  path="/update-category/:id"
                  element={<UpdateCategoryForm />}
                />
                {/* End категории */}
                {/* Start продукт */}
                <Route path="/all-product" element={<ProductsComponent />} />
                <Route path="/create-product" element={<CreateProductForm />} />
                <Route
                  path="/update-product/:id"
                  element={<UpdateProductForm />}
                />
                <Route path="/create-attribute" element={<CreateAttribute />} />
                <Route
                  path="/all-attribute"
                  element={<AttributesComponent />}
                />
                <Route
                  path="/update-attribute/:id"
                  element={<UpdateAttributeComponent />}
                />
                {/* End продукт */}

                <Route path="/create-store" element={<CreateStore />} />
                <Route path="/update-store/:id" element={<UpdateStore />} />
                <Route path="/all-store" element={<StoresList />} />

                {/* Start начинки */}
                <Route path="/all-filling" element={<FillingsList />} />
                <Route path="/create-filling" element={<CreateFillingForm />} />
                <Route
                  path="/update-filling/:id"
                  element={<UpdateFillingForm />}
                />
                {/* End начинки */}
                <Route path="/backup" element={<Database />} />

                {/* Start Articles */}
                <Route path="/create-article" element={<CreateArticleForm />} />
                <Route
                  path="/update-article/:articleId"
                  element={<UpdateArticleForm />}
                />
                <Route path="/all-articles" element={<ArticlesList />} />
                {/* End Articles */}

                <Route path="/visit" element={<VisitsByDate />} />
                <Route
                  path="/visit-details/:ipUserId"
                  element={<VisitsDetail />}
                />

                <Route path="/comments" element={<AllCommentsPage />} />
                <Route path="/add-comment" element={<AddCommentPage />} />
                <Route
                  path="/update-comment/:id"
                  element={<UpdateCommentPage />}
                />

                <Route
                  path="/admin/add-comment"
                  element={<AdminAddCommentPage />}
                />
              </Route>
              {/* END маршруты для авторизованных пользователей */}

              <Route path="/register" element={<RoleSelection />} />
              <Route path="/reg" element={<Reg />} />
              <Route path="/login" element={<Login />} />
              <Route path="/workout" element={<ExerciseProstate />} />

              <Route path="/vk-auth-test" element={<VKAuthTest />} />
              <Route path="/vk-auth" element={<VKAuth />} />
              <Route path="/" element={<BreathCircle duration={5} />} />

              <Route path="/logtest" element={<VkIdLogin />} />
              {/*VK*/}
              <Route path="/redirect-handler" element={<RedirectHandler />} />
              {/* Start YandexDirect */}
              <Route
                path="/redirect-handler-yandex-direct"
                element={<TokenHandlerYandexDirect />}
              />
              <Route
                path="/redirect-handler-yandex-webmaster"
                element={<TokenHandlerYandexWebmaster />}
              />
              {/* END YandexDirect */}
              <Route
                path="/redirect-handler-ok"
                element={<RedirectHandlerOk />}
              />
              {/* <Route path="/redirect-handler-yandex" element={<YandexRedirectHandler />} /> */}

              <Route
                path="/redirect-handler-yandex"
                element={<OAuthRedirect />}
              />
              <Route
                path="/redirect-handler-mail"
                element={<RedirectHandlerMail />}
              />
              {/*<Route path="/redirect-handler-telegram" element={<RedirectHandlerTelegram />} />*/}
              {/*<Route path="/telegram-login" element={<TelegramLogin />} />*/}
              <Route
                path="/redirect-handler-telegram"
                element={<TelegramLogin />}
              />
              <Route path="/sitemap" element={<GenerateSitemap />} />
            </Routes>
          </Router>
        </AlertProvider>
      </ModalProvider>
    </QueryClientProvider>
  );
};
export default App;

// Index.tsx
// Layout.tsx
// CreateUserFieldComponent.tsx
// UpdateUserFieldComponent.tsx
// CreateComponent.tsx
// UpdateComponent.tsx
