import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Добавляем импорт Link
import { CommentApi } from "../services/CommentApi";
import { CommentProduct } from "types/CommentProduct";
import Layout from "components/Layout/Layout";

const AllCommentsPage: React.FC = () => {
  const [comments, setComments] = useState<CommentProduct[]>([]);
  const [error, setError] = useState<string>("");
  const api = new CommentApi();

  useEffect(() => {
    const fetchAllComments = async () => {
      try {
        const data = await api.getAllComments();
        setComments(data);
      } catch (err: any) {
        setError(err.message);
      }
    };

    fetchAllComments();
  }, []);

  return (
    <Layout>
      <div>
        <h1>Все комментарии</h1>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {comments.length === 0 && <p>Комментариев пока нет</p>}

        {comments.map((comment) => (
          <div
            key={comment.id}
            style={{
              border: "1px solid #ccc",
              margin: "10px 0",
              padding: "10px",
            }}
          >
            <p>
              <strong>ID:</strong> {comment.id}
            </p>
            <p>
              <strong>Текст:</strong> {comment.commentText}
            </p>
            <p>
              <strong>Рейтинг:</strong> {comment.rating}
            </p>
            <p>
              <strong>Активен:</strong> {comment.active ? "Да" : "Нет"}
            </p>

            {/* Ссылка на страницу обновления комментария */}
            <Link
              to={`/update-comment/${comment.id}`}
              style={{ color: "blue", textDecoration: "underline" }}
            >
              Изменить
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default AllCommentsPage;
