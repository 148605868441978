import React, { useState } from "react";
import { CommentApi } from "../services/CommentApi";
import { CommentCreationRequest, CommentProduct } from "types/CommentProduct";
import Layout from "components/Layout/Layout";

const AddCommentPage: React.FC = () => {
  const [form, setForm] = useState<CommentCreationRequest>({
    productId: 0,
    commentText: "",
    rating: 5,
    phoneNumber: "",
  });
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const api = new CommentApi();

  /**
   * Обработчик изменения значений формы.
   */
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  /**
   * Обработчик отправки формы.
   */
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const newComment: CommentProduct = await api.createComment(form);
      setSuccess("Комментарий успешно добавлен");
      setError("");
      setForm({
        productId: 0,
        commentText: "",
        rating: 5,
        phoneNumber: "",
      });
    } catch (err: any) {
      setError(err.message);
      setSuccess("");
    }
  };

  return (
    <Layout>
      <div>
        <h1>Добавить комментарий</h1>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && <p style={{ color: "green" }}>{success}</p>}
        <form onSubmit={handleSubmit}>
          <div>
            <label>ID продукта:</label>
            <input
              type="number"
              name="productId"
              value={form.productId}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Текст комментария:</label>
            <textarea
              name="commentText"
              value={form.commentText}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Рейтинг:</label>
            <input
              type="number"
              name="rating"
              value={form.rating}
              onChange={handleChange}
              required
              min="1"
              max="5"
            />
          </div>
          <div>
            <label>Номер телефона:</label>
            <input
              type="text"
              name="phoneNumber"
              value={form.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">Добавить комментарий</button>
        </form>
      </div>
    </Layout>
  );
};

export default AddCommentPage;
