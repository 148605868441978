import { CommentCreationRequest, CommentProduct } from "types/CommentProduct";

/**
 * Класс для работы с API комментариев.
 */
export class CommentApi {
  private baseUrl: string;

  constructor() {
    // Базовый URL берётся из переменной окружения
    this.baseUrl =
      process.env.REACT_APP_API_URL || "http://localhost:8080/api/v1";
  }

  /**
   * Возвращает заголовки для запроса.
   * Для запросов, требующих авторизации, добавляется токен.
   * @param withAuth - использовать ли токен
   */
  private getHeaders(withAuth: boolean = false): HeadersInit {
    const headers: any = {
      "Content-Type": "application/json",
    };
    if (withAuth) {
      const token = localStorage.getItem("token");
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }
    }
    return headers;
  }

  /**
   * Получение комментариев для заданного productId.
   */
  async getComments(productId: number): Promise<CommentProduct[]> {
    const response = await fetch(
      `${this.baseUrl}/comments?productId=${productId}`,
      {
        headers: this.getHeaders(),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch comments");
    }
    return response.json();
  }

  /**
   * Создание комментария (пользовательский).
   */
  async createComment(data: CommentCreationRequest): Promise<CommentProduct> {
    const response = await fetch(`${this.baseUrl}/comments`, {
      method: "POST",
      headers: this.getHeaders(true),
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error("Failed to create comment");
    }
    return response.json();
  }

  /**
   * Обновление комментария по ID.
   */
  async updateComment(
    id: number,
    updatedData: Partial<CommentProduct>
  ): Promise<CommentProduct> {
    const response = await fetch(`${this.baseUrl}/comments/${id}`, {
      method: "PUT",
      headers: this.getHeaders(true),
      body: JSON.stringify(updatedData),
    });
    if (!response.ok) {
      throw new Error("Failed to update comment");
    }
    return response.json();
  }

  /**
   * Активация комментария.
   */
  async activateComment(id: number): Promise<CommentProduct> {
    const response = await fetch(`${this.baseUrl}/comments/${id}/activate`, {
      method: "PUT",
      headers: this.getHeaders(true),
    });
    if (!response.ok) {
      throw new Error("Failed to activate comment");
    }
    return response.json();
  }

  /**
   * Удаление комментария по ID.
   */
  async deleteComment(id: number): Promise<void> {
    const response = await fetch(`${this.baseUrl}/comments/${id}`, {
      method: "DELETE",
      headers: this.getHeaders(true),
    });
    if (!response.ok) {
      throw new Error("Failed to delete comment");
    }
  }

  /**
   * Создание комментария через админку (заполнение всех полей).
   */
  async createCommentAdmin(comment: CommentProduct): Promise<CommentProduct> {
    const response = await fetch(`${this.baseUrl}/comments/admin`, {
      method: "POST",
      headers: this.getHeaders(true),
      body: JSON.stringify(comment),
    });
    if (!response.ok) {
      throw new Error("Failed to create admin comment");
    }
    return response.json();
  }

  async getAllComments(): Promise<CommentProduct[]> {
    const response = await fetch(`${this.baseUrl}/comments/all`, {
      method: "GET",
      headers: this.getHeaders(false),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch all comments");
    }
    return response.json();
  }

  async getCommentById(id: number): Promise<CommentProduct> {
    const response = await fetch(`${this.baseUrl}/comments/${id}`, {
      method: "GET",
      headers: this.getHeaders(false),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch comment");
    }
    return response.json();
  }
}
