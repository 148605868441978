import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, FieldProps } from "formik";
import * as Yup from "yup";
import { CommentApi } from "../services/CommentApi";
import Layout from "components/Layout/Layout";
import { CommentProduct } from "types/CommentProduct";

// Компонент выбора товара с поиском
interface ProductSelectProps {
  value: number;
  onChange: (value: number) => void;
  products: any[];
}

const ProductSelect: React.FC<ProductSelectProps> = ({ value, onChange, products }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const selectedProduct = products.find(product => product.id === Number(value));

  // Фильтрация товаров по введённому значению (без учета регистра)
  const filteredProducts = products.filter(product =>
    product.productname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (product: any) => {
    onChange(product.id);
    setIsOpen(false);
    setSearchTerm("");
  };

  return (
    <div className="product-select" style={{ position: "relative" }}>
      {/* Поле для отображения выбранного товара */}
      <div
        className="selected-value"
        onClick={() => setIsOpen(prev => !prev)}
        style={{ border: "1px solid #ccc", padding: "8px", cursor: "pointer" }}
      >
        {selectedProduct ? selectedProduct.productname : "Выберите товар"}
      </div>
      {/* Выпадающий список с поиском */}
      {isOpen && (
        <div
          className="dropdown"
          style={{
            position: "absolute",
            zIndex: 1000,
            backgroundColor: "white",
            border: "1px solid #ccc",
            width: "100%",
            maxHeight: "200px",
            overflowY: "auto"
          }}
        >
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Поиск..."
            style={{ width: "100%", boxSizing: "border-box", padding: "8px" }}
          />
          {filteredProducts.map(product => (
            <div
              key={product.id}
              onClick={() => handleSelect(product)}
              style={{ padding: "8px", cursor: "pointer" }}
            >
              {product.productname}
            </div>
          ))}
          {filteredProducts.length === 0 && (
            <div style={{ padding: "8px" }}>Нет результатов</div>
          )}
        </div>
      )}
    </div>
  );
};

const UpdateCommentPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const commentId = id ? parseInt(id, 10) : 0;
  const [comment, setComment] = useState<any>(null);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [products, setProducts] = useState<any[]>([]);
  const api = new CommentApi();

  // Загрузка комментария
  useEffect(() => {
    const fetchComment = async () => {
      try {
        const current = await api.getCommentById(commentId);
        setComment(current);
      } catch (err: any) {
        setError(err.message);
      }
    };
    if (commentId) fetchComment();
  }, [commentId]);

  // Загрузка списка товаров
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/products/allproduct`
        );
        const data = await response.json();
        console.log("Данные:", JSON.stringify(data, null, 2));
        setProducts(data);
      } catch (err: any) {
        console.error(err);
      }
    };
    fetchProducts();
  }, []);

  const handleDelete = async () => {
    if (window.confirm("Вы уверены, что хотите удалить комментарий?")) {
      try {
        await api.deleteComment(comment.id);
        setSuccess("Комментарий удалён");
        // Можно добавить редирект после удаления
      } catch (err: any) {
        setError(err.message);
      }
    }
  };

  if (!comment) {
    return <div>{error || "Загрузка комментария..."}</div>;
  }

  return (
    <Layout>
      <div>
        <h1>Обновить комментарий</h1>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && <p style={{ color: "green" }}>{success}</p>}
        <Formik
          initialValues={{
            commentText: comment.commentText,
            productId: comment.productId,
            active: comment.active,
          }}
          validationSchema={Yup.object({
            commentText: Yup.string().required("Обязательное поле"),
            productId: Yup.number().required("Обязательное поле"),
            active: Yup.boolean(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            const updatedData: Partial<CommentProduct> = {
              commentText: values.commentText,
              productId: Number(values.productId),
              active: values.active,
            };

            api
              .updateComment(comment.id, updatedData)
              .then((updated) => {
                setComment(updated);
                setSuccess("Комментарий успешно обновлён");
                setError("");
              })
              .catch((err: any) => {
                setError(err.message);
                setSuccess("");
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <table className="table">
                <tbody>
                  <tr>
                    <td className="col-4">
                      <label>Текст комментария</label>
                    </td>
                    <td className="col-8">
                      <Field
                        as="textarea"
                        name="commentText"
                        className="form-control"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4">
                      <label>Товар</label>
                    </td>
                    <td className="col-8">
                      {/* Используем Field с рендер-пропсом для подключения кастомного селекта */}
                      <Field name="productId">
                        {({ field, form }: FieldProps<number>) => (
                          <ProductSelect
                            value={field.value}
                            onChange={(value: number) => form.setFieldValue(field.name, value)}
                            products={products}
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4">
                      <label>Активен</label>
                    </td>
                    <td className="col-8">
                      <Field type="checkbox" name="active" />
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4">
                      <label>Пользователь</label>
                    </td>
                    <td className="col-8">
                      <Field
                        name="userName"
                        disabled
                        value={comment.user?.username || ""}
                        className="form-control"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ marginTop: "15px" }}>
                <button type="submit" disabled={isSubmitting}>
                  Обновить комментарий
                </button>
                <button
                  type="button"
                  onClick={handleDelete}
                  style={{ marginLeft: "15px" }}
                >
                  Удалить комментарий
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default UpdateCommentPage;
