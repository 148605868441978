import React, { useState } from 'react';
import { CommentApi } from '../services/CommentApi';
import { CommentProduct } from 'types/CommentProduct';

const AdminAddCommentPage: React.FC = () => {
  const [form, setForm] = useState<CommentProduct>({
    commentText: '',
    productId: 0,
    rating: 5,
    active: false,
    sortOrder: 0
  });
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const api = new CommentApi();

  /**
   * Обработчик изменения формы.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]:
        name === 'productId' || name === 'rating' || name === 'sortOrder'
          ? Number(value)
          : value
    });
  };

  /**
   * Обработчик отправки формы.
   */
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await api.createCommentAdmin(form);
      setSuccess('Комментарий успешно добавлен через админку');
      setError('');
      setForm({
        commentText: '',
        productId: 0,
        rating: 5,
        active: false,
        sortOrder: 0
      });
    } catch (err: any) {
      setError(err.message);
      setSuccess('');
    }
  };

  return (
    <div>
      <h1>Админка: Добавить комментарий</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>ID продукта:</label>
          <input type="number" name="productId" value={form.productId} onChange={handleChange} required />
        </div>
        <div>
          <label>Текст комментария:</label>
          <textarea name="commentText" value={form.commentText} onChange={handleChange} required />
        </div>
        <div>
          <label>Рейтинг:</label>
          <input type="number" name="rating" value={form.rating} onChange={handleChange} required min="1" max="5" />
        </div>
        <div>
          <label>Активен:</label>
          <input
            type="checkbox"
            name="active"
            checked={form.active || false}
            onChange={(e) => setForm({ ...form, active: e.target.checked })}
          />
        </div>
        <div>
          <label>Порядок сортировки:</label>
          <input type="number" name="sortOrder" value={form.sortOrder} onChange={handleChange} required />
        </div>
        <button type="submit">Добавить комментарий</button>
      </form>
    </div>
  );
};

export default AdminAddCommentPage;
